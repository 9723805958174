.Card {
  /*max-width: 70%;*/
}

.CardMedia {
  height: 0;
  padding-top: '56.25%'; /* 16:9 */
}

.CardExpand {
  transform: auto;
  margin-left: auto;
  transition-property: transform;
  transition-duration: 1s;
}

.CardExpandOpen {
  transform: 'rotate(180deg)';
}

.CardAvatar {
  background-color: red;
}

.CardStatusSpan {
  border-radius: 5px;
  padding: 6px;
  color: white;
  font-size: 1.2em;
}