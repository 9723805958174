/** IMAGE **/

.omnichannel-chat-message-image {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.omnichannel-chat-message-image-icon img {
  width: 4em;
  border-radius: 8px;
}

.omnichannel-chat-message-image-name {
  padding-left: 10px;
  color: #525252;
}

/** IMAGE DIALOG **/

.chat-message-image-dialog {
  display: grid;
  height: 100%;
}

.chat-message-image-dialog img {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

/** DOCUMENT **/

.omnichannel-chat-message-document {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.omnichannel-chat-message-document-icon img {
  width: 4em;
  border-radius: 8px;
}

.omnichannel-chat-message-document-name {
  padding-left: 10px;
  color: #525252;
}

/** LOCATION **/

.omnichannel-chat-message-location {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.omnichannel-chat-message-location-icon img {
  width: 4em;
  border-radius: 8px;
}

.omnichannel-chat-message-location-name {
  padding-left: 10px;
  color: #525252;
}

/** LOCATION DIALOG **/

.chat-message-location-dialog .leaflet-container {
  max-height: 400px;
}

/** AUDIO **/

.omnichannel-chat-message-audio {
  display: flex;
  align-items: center;
}

.omnichannel-chat-message-audio-icon img {
  width: 4em;
  border-radius: 8px;
}

.omnichannel-chat-message-audio-name {
  padding: 10px 0 10px 10px;
  color: #525252;
}

.omnichannel-chat-message-audio-name audio {
  background-color: #CDDFE7;
}

/** VIDEO **/

.omnichannel-chat-message-video {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.omnichannel-chat-message-video-icon img {
  width: 4em;
  border-radius: 8px;
}

.omnichannel-chat-message-video-name {
  padding-left: 10px;
  color: #525252;
}

/** VIDEO DIALOG **/

.chat-message-video-dialog {
  display: grid;
  height: 100%;
}

.chat-message-video-dialog img {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

/** CONTACT **/

.omnichannel-chat-message-contact {
  min-width: 250px;
}

.omnichannel-chat-message-contact-icon {
  display: flex;
  justify-content: center;
}

.omnichannel-chat-message-contact-name {
  display: flex;
  flex-direction: column;
}