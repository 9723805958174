.btn{
    background:#3f51b5;
    color:white;
    padding-top:15px;
    padding-bottom:15px;
    padding-left:10px;
    padding-right:10px;
    transition: 0.4s;
}
  
  .btn:hover {
    color:#f47920;
    background: white;
    transition: 0.4s;
  }

  .react-tabs__tab--selected{
    color:#f47920 !important; 
    background: white !important;
    border-color:#BB8FCE !important;
  }
  
.calledViewDescription img {
  max-width: 100%!important;
}
  
  