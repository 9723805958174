.orgfieldblocks {
  list-style: none;
  padding-left: 0;
}

.orgfieldblocks li {
  display: flex;
  align-items: center;
  border: solid 2px #a1a1a1;
  border-radius: .2em;
  padding: .5em .8em .5em .5em;
  margin-bottom: 1em;
}

.orgfieldblocks p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.orgfieldblocks-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: .5em;
  margin-right: .5em;
}

.orgfieldblocks-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.DataGridStyleCallReportEdit {
  font-size: 12px!important;
  color: #000!important;
}

.MuiDialog-root .MuiDialog-container .MuiPaper-root {
  overflow: hidden!important;
}

.MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  overflow: hidden!important;
}

.MuiDataGrid-footerContainer {
  justify-content: flex-end!important;
}

.MuiDataGrid-selectedRowCount {
  display: none!important;
}

.MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
}