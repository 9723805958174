.border{

    transition: 0.4s;
    border-color:'#BB8FCE' !important;
}
  
  
  .react-tabs__tab--selected{
    color:#f47920 !important; 
    background: white !important;
    border-color:#BB8FCE !important;
  }

  
  