.button {
    margin-bottom: 10px;
    margin-left: "auto" ;
    justify-content: 'flex-end';
}
.home-page {
    margin-top: 70px;
    background-color: '#fff';
}
.center {
    background-color: '#fff';
}
