li[class^="ReactFlagsSelect-module_selectOption"] {
  color: black;
}


.reactFlagsSelectCustom {
  color: black;
  position: fixed;
  top: 3%;
  right: 3%;
  width: 130px;
}

.reactFlagsSelectCustom button[class^="ReactFlagsSelect-module_selectBtn"]::after {
  border-top-color: black;
  border-bottom-color: black;
}

ul[class^="ReactFlagsSelect-module_selectOptions"] {
  width: 130px;
  position: fixed;
  top: 12%;
  right: 3%;
  left: auto;
}

.loginFooterKnowLedgeBaseFloatingButton {
  position: fixed;
  bottom: 1%;
  right: 3%;
}

/*.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}*/

.me-main-container-grid {
  display: flex;
  flex-direction: row!important;
}

.me-wallpaper-block {
  width: 45%;
  border-top-left-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 50px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 4%;
}

.me-form-block {
  width: 55%;
  background-color: #F1F1F1;
  padding: 2% 5% 2% 5%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 0;
}

.me-form-more-buttons-container {
  padding: 0 5%;
}

/* Small devices such as large phones (640px and up) */
@media (max-width: 959.9px) { /* SM */
  .me-main-container-grid {
    display: flex;
    flex-direction: column!important;
  }

  .me-wallpaper-block {
    width: 100%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 0;
    min-height: 250px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 4%;
  }

  .me-form-block {
    width: 100%;
    background-color: #F1F1F1;
    padding: 2% 5% 2% 5%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }
}

/* Medium devices such as tablets (768px and up) */
@media (min-width: 960px) and (max-width: 1199.9px) { /* MD */
  .me-main-container-grid {
    display: flex;
    flex-direction: row!important;
  }

  .me-wallpaper-block {
    width: 45%;
    border-top-left-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 50px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 4%;
  }

  .me-form-block {
    width: 55%;
    background-color: #F1F1F1;
    padding: 2% 5% 2% 5%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 0;
  }
}

/* Large devices such as laptops (1024px and up) */
@media (min-width: 1200px) and (max-width: 1535.9px) { /* LG */
  .me-main-container-grid {
    display: flex;
    flex-direction: row!important;
  }

  .me-wallpaper-block {
    width: 45%;
    border-top-left-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 50px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 4%;
  }

  .me-form-block {
    width: 55%;
    background-color: #F1F1F1;
    padding: 2% 5% 2% 5%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 0;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media (min-width: 1536px) { /* XL */
  .me-main-container-grid {
    display: flex;
    flex-direction: row!important;
  }

  .me-wallpaper-block {
    width: 45%;
    border-top-left-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 50px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 4%;
  }

  .me-form-block {
    width: 55%;
    background-color: #F1F1F1;
    padding: 2% 5% 2% 5%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 0;
  }
}