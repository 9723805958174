.FirstElement
{
    height:70vh;
    /*border:1px solid red;*/
  display: flex;
  align-items: center;
  justify-content: center ;
}

.regularFont  {
    color: #212F3C;
   
  
}

.grayFont  {
    color: #909497;
    font-weight: 500; 
    font-size: 14px;
}
