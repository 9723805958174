.omnichannel-chat-menu {
  width: 25%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(82, 82, 82, .2);
}

.omnichannel-chat-menu-header {
  background-color: #F3F3F3;
  padding: 3%;
  flex: 0 1 0;
}

.omnichannel-chat-menu-header-title {
  flex-direction: row!important;
}

.omnichannel-chat-menu-header-total-messages {
  background-color: #5667c4;
  width: 35px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  border-radius: 5px;
}

.omnichannel-chat-menu-user-list {
  background-color: #F3F3F3;
  padding: 4% 2% 2% 2%;
  flex: 3 0 0;
  overflow-y: auto;
}

.omnichannel-chat-menu-user-list::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
.omnichannel-chat-menu-user-list::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.omnichannel-chat-menu-user-list::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.omnichannel-chat-menu-user-list::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.omnichannel-chat-menu-user-info-root {
  background-color: #EEEEEE;
  padding: 2%;
  margin-bottom: 3%;
  border-radius: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(82, 82, 82, .2);
}

.omnichannel-chat-menu-user-info {
  background-color: #EEEEEE;
  padding: 2%;
  margin-bottom: 3%;
  border-radius: 10px;
  cursor: pointer;
}

.omnichannel-chat-menu-user-info-selected {
  background-color: #CDDFE7;
}

.omnichannel-chat-menu-user-info-header {
  padding: 1% 2%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #5667c4;
  fill: #5667c4;
  font-size: 1.0em;
  font-weight: 700;
}

@media (max-width: 1199.9px) {
  .omnichannel-chat-menu-header-title {
    flex-direction: column!important;
  }
}

@media (max-width: 899.9px) {
  .omnichannel-chat-menu-user-info-message {
    display: none!important;
  }
}

@media (min-width: 900px) {
  .omnichannel-chat-menu-user-info-message {
    display: block!important;
  }
}

@media (max-width: 1049.9px) {
  .omnichannel-chat-menu-user-info-header-icon {
    display: none;
  }
}

@media (min-width: 1050px) {
  .omnichannel-chat-menu-user-info-header-icon {
    display: block;
  }
}