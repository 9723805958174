div:first-child p {
  font-weight: normal!important;
}

.user-action-name {
  font-weight: bold!important;
}

.action-text-content img {
  max-width: 90%;
}