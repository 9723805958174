.containerimgstep{
    display: flex;
   
}

/*principal divs*/
.img-size {
    height: 100px;
    width: 120px;
}
.div-image{
    margin-right: 40px;
}



/*general configurations*/
.h4-principal
{
    color : #404b56;
    font-weight: 700;
    margin-bottom: 15px;
}

.h4-title
{
    color : #566573;
    font-weight: 700;
    margin-bottom: 15px;
}

.h4-subtitle
{
    color: #909497 ;
    font-size: 15px;
    font-weight: 500;
}

.classeteste
{
    text-decoration: line-through;
}

