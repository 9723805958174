.technician-chat-container {
  width: 565px;
  top: initial;
  right: 30px;
  bottom: 70px;
  z-index: 10000;
  position: fixed;

  .header-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: #2d2ddd;
    display: flex;
    align-items: center;
    color: white;
    position: relative;
    cursor: pointer;

    .chat-button {
      width: 10%;
      display: flex;
      justify-content: flex-end;
    }
    
    .header-content {
      width: 100%;
      font-size: 1.1rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      .title {
        font-size: 1.24rem;
        margin-bottom: .2em;
      }

      .username {
        font-size: 1.02rem;
      }
      .organization {
        font-size: .87rem;
      }

      justify-content: center;
      padding: 0 1.1em;
    }

  }
  .chat-list-container {
    
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
    border-bottom-left-radius: 1em;
    height: 325px;
    overflow-y: scroll;
    width: 300px;
    top: initial;
    right: 330px;
    bottom: 50px;
    background-color: rgb(240, 240, 240);
    -webkit-box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
    -moz-box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
    box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
  }
  
  .chat-container {
    border-radius: 1em;
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    z-index: 9000;
    position: fixed;
    z-index: 1000;
    top: initial;
    right: 30px;
    bottom: 70px;
    position: fixed;
    height: 325px;
    width: 265px;
    background-color: rgb(240, 240, 240);
    -webkit-box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
    -moz-box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
    box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
  
  
    .chat-main-content {
      overflow-y: scroll;
      height: calc(100% - 48px);
      padding: 1.2em 1.2em;
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      
      .message {
        max-width: 82%;
        word-break: break-all;
        padding: .4em .8em;
        border-radius: .4em;
        margin-bottom: .7em;
        font-size: 1.02rem;
        color: white;
        background-color: #e3e3e3;
        color: black;
        width: fit-content;
        display: block;
      }
      
      .receiver {
        display: flex;
        border-bottom-left-radius: 0;
      }
      .sender {
        display: flex;
        border-bottom-right-radius: 0;
        justify-content: flex-end;
      }
    }

    .disabled-main-content{

      background-color: #F0F0F0;

    }
    .chat-input-container {
      background-color: rgb(231, 231, 231);
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1em;
      border-top-left-radius: 0;
      .input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: .3em 1.24em;
        padding-left: 1em;
        
        .chat-input {
          border: none;
          color: black;
          background-color: inherit;
          font-size: 1.2rem;
          padding-left: .6em;
          width: 82%;
          padding: .3em .7em;
          border-radius: .3em;
        }
      }
  
      .send-icon {
        margin-right: 5px;
        
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
