li[class^="ReactFlagsSelect-module_selectOption"] {
  color: black;
}

.reactFlagsSelectCustom {
  color: black;
  position: fixed;
  top: 3%;
  right: 3%;
  width: 130px;
}

.reactFlagsSelectCustom button[class^="ReactFlagsSelect-module_selectBtn"]::after {
  border-top-color: black;
  border-bottom-color: black;
}

ul[class^="ReactFlagsSelect-module_selectOptions"] {
  width: 130px;
  position: fixed;
  top: 12%;
  right: 3%;
  left: auto;
}

/*body {
  margin: 0;
  padding-right: 0!important;
  overflow-x: hidden!important;
  padding-bottom: 0!important;
}*/

/* New login */
@media (min-width: 1536px) { /* XL */
  .mainContainer {
    padding-right: 0!important;
    padding-left: 5%!important;
  }
  
  .loginFormCenter {
    padding-left: 5%!important;
    padding-right: 15%!important;
  }

  .mediumLargeLogos {
    display: inline!important;
  }

  .svgXL {
    display: inline!important;
  }

  .svdLg {
    display: none!important;
  }

  .svgMd {
    display: none!important;
  }

  .svgSm {
    display: none!important;
  }

  .loginGrid {
    align-items: flex-start!important;
    justify-content: flex-start!important;
    min-height: 100vh!important;
  }

  .loginInsideGrid {
    min-height: 100vh!important;
  }

  .smallLogo {
    display: none!important;
  }

  /*body, .root {
    overflow-y: hidden!important;
    overflow-x: hidden!important;
  }*/
}

@media (min-width: 1350px) and (max-width: 1535.9px) {

}

@media (min-width: 1200px) and (max-width: 1535.9px) { /* LG */
  .mainContainer {
    padding-right: 0!important;
    padding-left: 2.5%!important;
  }
  
  .loginFormCenter {
    padding-left: 5!important;
    padding-right: 5%!important;
  }

  .mediumLargeLogos {
    display: inline!important;
  }

  .svdLg {
    display: flex!important;
    justify-content: flex-end!important;
  }

  .svgXL {
    display: none!important;
  }

  .svgMd {
    display: none!important;
  }

  .svgSm {
    display: none!important;
  }

  .loginGrid {
    align-items: flex-start!important;
    justify-content: flex-start!important;
    min-height: 100vh!important;
  }

  .loginInsideGrid {
    min-height: 100vh!important;
  }

  .smallLogo {
    display: none!important;
  }

  /*body, .root {
    overflow-y: hidden!important;
    overflow-x: hidden!important;
  }*/
}

@media (min-width: 960px) and (max-width: 1199.9px) { /* MD */
  .mainContainer {
    padding-right: 0!important;
    padding-left: 0!important;
  }

  .loginFormCenter {
    padding-left: 0!important;
    padding-right: 0!important;
  }

  .mediumLargeLogos {
    display: none!important;
  }

  .svdLg {
    display: none!important;
  }

  .svgXL {
    display: none!important;
  }

  .svgMd {
    display: inline!important;
  }

  .svgSm {
    display: none!important;
  }

  .loginGrid {
    align-items: center!important;
    justify-content: center!important;
    min-height: 0vh!important;
  }

  .loginInsideGrid {
    min-height: 0!important;
  }

  .smallLogo {
    display: flex!important;
  }

  /*body, .root {
    overflow-y: auto!important;
    overflow-x: hidden!important;
  }*/
}

@media (max-width: 959.9px) { /* SM */
  .mainContainer {
    padding-right: 0!important;
    padding-left: 0!important;
  }

  .loginFormCenter {
    padding-left: 0!important;
    padding-right: 0!important;
  }

  .mediumLargeLogos {
    display: none!important;
  }

  .svdLg {
    display: none!important;
  }

  .svgXL {
    display: none!important;
  }

  .svgMd {
    display: none!important;
  }

  .svgSm {
    display: inline!important;
  }

  .loginGrid {
    align-items: center!important;
    justify-content: center!important;
    min-height: 0!important;
  }

  .loginInsideGrid {
    min-height: 0!important;
  }

  .smallLogo {
    display: flex!important;
  }

  /*body, .root {
    overflow-y: auto!important;
    overflow-x: hidden!important;
  }*/
}

.mainContainer {
  padding-right: 0;
  padding-left: 5%;
}

.loginFormCenter {
  padding-left: 10%;
  padding-right: 10%;
}

.mediumLargeLogos {
  display: inline;
}

.svdLg {
  display: none;
}

.svgXL {
  display: inline;
}

.svgMd {
  display: none;
}

.svgSm {
  display: none;
}

.loginGrid {
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100vh;
}

.loginInsideGrid {
  min-height: 100vh;
}

.smallLogo {
  display: none;
}

/*body, .root {
  overflow-y: hidden;
  overflow-x: hidden;
}*/

/*#eWNMhAkwmiD4_ts {
  animation: eWNMhAkwmiD4_ts__ts 3000ms linear infinite normal forwards
}
@keyframes eWNMhAkwmiD4_ts__ts {
  0% {transform: translate(12.938px,9.085px) scale(1,1)}
  50% {transform: translate(12.938px,9.085px) scale(1.05,1.05)}
  100% {transform: translate(12.938px,9.085px) scale(1,1)}
}
#eWNMhAkwmiD4 {
  animation: eWNMhAkwmiD4__tt 3000ms linear infinite normal forwards
}

@keyframes eWNMhAkwmiD4__tt {
  0% {transform: translate(0px,0px)}
  50% {transform: translate(-70px,0px)}
  100% {transform: translate(0px,0px)}
}

#eWNMhAkwmiD7_ts {
  animation: eWNMhAkwmiD7_ts__ts 3000ms linear infinite normal forwards
}

@keyframes eWNMhAkwmiD7_ts__ts {
  0% {transform: translate(12.938px,5.496px) scale(1,1)}
  50% {transform: translate(12.938px,5.496px) scale(1.05,1.05)}
  100% {transform: translate(12.938px,5.496px) scale(1,1)}
}

#eWNMhAkwmiD7 {
  animation: eWNMhAkwmiD7__tt 3000ms linear infinite normal forwards
}

@keyframes eWNMhAkwmiD7__tt {
  0% {transform: translate(0px,0px)}
  50% {transform: translate(-70px,0px)}
  100% {transform: translate(0px,0px)}
}

#eWNMhAkwmiD10_ts {
  animation: eWNMhAkwmiD10_ts__ts 3000ms linear infinite normal forwards
}

@keyframes eWNMhAkwmiD10_ts__ts {
  0% {transform: translate(1122.25px,145.872px) scale(1,1)}
  50% {transform: translate(1122.25px,145.872px) scale(1.05,1.05)}
  100% {transform: translate(1122.25px,145.872px) scale(1,1)}
}*/