.client-chat-container {
  border-radius: 1em;
  top: initial;
  right: 320px;
  bottom: 50px;
  position: fixed;
  z-index: 1000;
  margin-top: 4em;
  height: 370px;
  z-index: 10000;
  width: 265px;
  background-color: rgb(240, 240, 240);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
  -moz-box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);
  box-shadow: 0px 0px 13px 0px rgba(50, 50, 50, 0.43);

  .header-container {
    height: 55px;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: #2d2ddd;
    display: flex;
    align-items: center;
    color: white;
    position: relative;
    cursor: pointer;

    .chat-button {
      position: absolute;
      top: 9px;
      right: 9px;
    }
    
    .header-content {
      width: 100%;
      font-size: .9rem;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 1.22rem;
        margin-bottom: .2em;
      }

      .username {
        font-size: 1.09rem;
      }
      .organization {
        font-size: .87rem;
      }

      justify-content: center;
      padding: 0 1.1em;
    }

  }

  .chat-main-content {
    overflow-y: scroll;
    height: calc(100% - 55px - 48px);
    padding: 1.2em 1.2em;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    
    .message {
      max-width: 82%;
      word-break: break-all;
      padding: .4em .8em;
      border-radius: .4em;
      margin-bottom: .7em;
      font-size: 1.02rem;
      color: black;
      background-color: #e3e3e3;
      width: fit-content;
      display: flex;
    }
    
    .receiver {
      display: flex;
      border-bottom-left-radius: 0;
    }
    .sender {
      display: flex;
      border-bottom-right-radius: 0;
      justify-content: flex-end;
    }
  }
  .chat-input-container {
    background-color: rgb(231, 231, 231);
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    border-top-left-radius: 0;
    .input-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: .3em 1.24em;
      padding-left: 1em;
      
      .chat-input {
        border: none;
        color: black;
        background-color: inherit;
        font-size: 1.2rem;
        padding: .3em .7em;
        padding-left: .6em;
        width: 82%;
        border-radius: .3em;
      }
    }

    .send-icon {
      margin-right: 5px;
      &:hover {
        cursor: pointer;
      }
    }

    .closechat-icon {
      &:hover {
        cursor: pointer;
      }
      height: 28px;
      width: 28px;
    }
  }
}