@font-face {
    font-family: "realist";
    src: url("./realist400.ttf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

/* Material design icons (https://material.io/resources/icons) */
@font-face {
    font-family: 'material-icons';
    src: url('./MaterialIcons-Regular.woff2') format('woff2'),
    url('./MaterialIcons-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@mixin icon {
    font-family: 'material-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
