.knowledgebase-dialog-client-details {
  overflow-y: auto!important;
}

.material-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.kb-card {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 15px 20px;
  background-color: #d3d3d333;
  flex: 30%;
  margin: 15px 1%;
  box-sizing: border-box;
  max-width: 31.33%;
  cursor: pointer;
}

.kb-card-details {
  display: flex;
  flex-direction: column;
}

.kb-card-more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  font-size: 3em;
  color: #3f51b4;
  font-weight: bold;
  cursor: pointer;
}

.kb-title {
  font-size: 1.7em;
  margin-bottom: 5px;
}

.kb-key-title {
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kb-keys {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
}

.kb-key {
  background-color: #fff;
  border: 1px solid #277afe;
  margin-right: 10px;
  padding: 2px 5px;
  border-radius: 8px;
  color: #277afe;
  font-weight: 600;
  text-align: center;
}

.kb-key-tooltip {
  margin-top: 15px;
}

.kb-tooltip-root {
  max-width: 500px;
}

.kb-tooltip {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: column;
  flex: 30%;
}

@media (min-width: 1536px) { /* XL */
  .kb-card {
    flex: 30%;
    max-width: 85.00%;
  }
}

/*@media (min-width: 1350px) and (max-width: 1535.9px) {
  .kb-card {
    flex: 30%;
    max-width: 31.33%;
  }
}*/


@media (min-width: 1200px) and (max-width: 1535.9px) { /* LG */
  .kb-card {
    flex: 30%;
    max-width: 60%;
  }
}

@media (min-width: 960px) and (max-width: 1199.9px) { /* MD */
  .kb-card {
    flex: 48%;
    max-width: 48%;
  }
}

@media (max-width: 959.9px) { /* SM */
  .kb-card {
    flex: 100%;
    max-width: 100%;
  }
}