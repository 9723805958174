.DataGridStyle {
  font-size: 14px!important;
  color: #000!important;
}

@keyframes fadeInOpacity {
  0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}